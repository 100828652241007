let searchOption = {
    data() {
        return {
            areaOptions: [{
                "value": "广东",
                "label": "广东",
                "children": [{
                    "value": "广州市",
                    "label": "广州市"
                }, {
                    "value": "韶关市",
                    "label": "韶关市"
                }, {
                    "value": "深圳市",
                    "label": "深圳市"
                }, {
                    "value": "珠海市",
                    "label": "珠海市"
                }, {
                    "value": "汕头市",
                    "label": "汕头市"
                }, {
                    "value": "佛山市",
                    "label": "佛山市"
                }, {
                    "value": "江门市",
                    "label": "江门市"
                }, {
                    "value": "湛江市",
                    "label": "湛江市"
                }, {
                    "value": "茂名市",
                    "label": "茂名市"
                }, {
                    "value": "肇庆市",
                    "label": "肇庆市"
                }, {
                    "value": "惠州市",
                    "label": "惠州市"
                }, {
                    "value": "梅州市",
                    "label": "梅州市"
                }, {
                    "value": "汕尾市",
                    "label": "汕尾市"
                }, {
                    "value": "河源市",
                    "label": "河源市"
                }, {
                    "value": "阳江市",
                    "label": "阳江市"
                }, {
                    "value": "清远市",
                    "label": "清远市"
                }, {
                    "value": "东莞市",
                    "label": "东莞市"
                }, {
                    "value": "中山市",
                    "label": "中山市"
                }, {
                    "value": "潮州市",
                    "label": "潮州市"
                }, {
                    "value": "揭阳市",
                    "label": "揭阳市"
                }, {
                    "value": "云浮市",
                    "label": "云浮市"
                }]
            }, {
                "value": "北京",
                "label": "北京",
                "children": [{
                    "value": "北京市",
                    "label": "北京市"
                }]
            }, {
                "value": "江苏",
                "label": "江苏",
                "children": [{
                    "value": "南京市",
                    "label": "南京市"
                }, {
                    "value": "无锡市",
                    "label": "无锡市"
                }, {
                    "value": "徐州市",
                    "label": "徐州市"
                }, {
                    "value": "常州市",
                    "label": "常州市"
                }, {
                    "value": "苏州市",
                    "label": "苏州市"
                }, {
                    "value": "南通市",
                    "label": "南通市"
                }, {
                    "value": "连云港市",
                    "label": "连云港市"
                }, {
                    "value": "淮安市",
                    "label": "淮安市"
                }, {
                    "value": "盐城市",
                    "label": "盐城市"
                }, {
                    "value": "扬州市",
                    "label": "扬州市"
                }, {
                    "value": "镇江市",
                    "label": "镇江市"
                }, {
                    "value": "泰州市",
                    "label": "泰州市"
                }, {
                    "value": "宿迁市",
                    "label": "宿迁市"
                }]
            }, {
                "value": "上海",
                "label": "上海",
                "children": [{
                    "value": "上海市",
                    "label": "上海市"
                }]
            }, {
                "value": "浙江",
                "label": "浙江",
                "children": [{
                    "value": "杭州市",
                    "label": "杭州市"
                }, {
                    "value": "宁波市",
                    "label": "宁波市"
                }, {
                    "value": "温州市",
                    "label": "温州市"
                }, {
                    "value": "嘉兴市",
                    "label": "嘉兴市"
                }, {
                    "value": "湖州市",
                    "label": "湖州市"
                }, {
                    "value": "绍兴市",
                    "label": "绍兴市"
                }, {
                    "value": "金华市",
                    "label": "金华市"
                }, {
                    "value": "衢州市",
                    "label": "衢州市"
                }, {
                    "value": "舟山市",
                    "label": "舟山市"
                }, {
                    "value": "台州市",
                    "label": "台州市"
                }, {
                    "value": "丽水市",
                    "label": "丽水市"
                }]
            }, {
                "value": "四川",
                "label": "四川",
                "children": [{
                    "value": "成都市",
                    "label": "成都市"
                }, {
                    "value": "自贡市",
                    "label": "自贡市"
                }, {
                    "value": "攀枝花市",
                    "label": "攀枝花市"
                }, {
                    "value": "泸州市",
                    "label": "泸州市"
                }, {
                    "value": "德阳市",
                    "label": "德阳市"
                }, {
                    "value": "绵阳市",
                    "label": "绵阳市"
                }, {
                    "value": "广元市",
                    "label": "广元市"
                }, {
                    "value": "遂宁市",
                    "label": "遂宁市"
                }, {
                    "value": "内江市",
                    "label": "内江市"
                }, {
                    "value": "乐山市",
                    "label": "乐山市"
                }, {
                    "value": "南充市",
                    "label": "南充市"
                }, {
                    "value": "眉山市",
                    "label": "眉山市"
                }, {
                    "value": "宜宾市",
                    "label": "宜宾市"
                }, {
                    "value": "广安市",
                    "label": "广安市"
                }, {
                    "value": "达州市",
                    "label": "达州市"
                }, {
                    "value": "雅安市",
                    "label": "雅安市"
                }, {
                    "value": "巴中市",
                    "label": "巴中市"
                }, {
                    "value": "资阳市",
                    "label": "资阳市"
                }, {
                    "value": "阿坝藏族羌族自治州",
                    "label": "阿坝藏族羌族自治州"
                }, {
                    "value": "甘孜藏族自治州",
                    "label": "甘孜藏族自治州"
                }, {
                    "value": "凉山彝族自治州",
                    "label": "凉山彝族自治州"
                }]
            }, {
                "value": "山东",
                "label": "山东",
                "children": [{
                    "value": "济南市",
                    "label": "济南市"
                }, {
                    "value": "青岛市",
                    "label": "青岛市"
                }, {
                    "value": "淄博市",
                    "label": "淄博市"
                }, {
                    "value": "枣庄市",
                    "label": "枣庄市"
                }, {
                    "value": "东营市",
                    "label": "东营市"
                }, {
                    "value": "烟台市",
                    "label": "烟台市"
                }, {
                    "value": "潍坊市",
                    "label": "潍坊市"
                }, {
                    "value": "济宁市",
                    "label": "济宁市"
                }, {
                    "value": "泰安市",
                    "label": "泰安市"
                }, {
                    "value": "威海市",
                    "label": "威海市"
                }, {
                    "value": "日照市",
                    "label": "日照市"
                }, {
                    "value": "莱芜市",
                    "label": "莱芜市"
                }, {
                    "value": "临沂市",
                    "label": "临沂市"
                }, {
                    "value": "德州市",
                    "label": "德州市"
                }, {
                    "value": "聊城市",
                    "label": "聊城市"
                }, {
                    "value": "滨州市",
                    "label": "滨州市"
                }, {
                    "value": "菏泽市",
                    "label": "菏泽市"
                }]
            }, {
                "value": "河北",
                "label": "河北",
                "children": [{
                    "value": "石家庄市",
                    "label": "石家庄市"
                }, {
                    "value": "唐山市",
                    "label": "唐山市"
                }, {
                    "value": "秦皇岛市",
                    "label": "秦皇岛市"
                }, {
                    "value": "邯郸市",
                    "label": "邯郸市"
                }, {
                    "value": "邢台市",
                    "label": "邢台市"
                }, {
                    "value": "保定市",
                    "label": "保定市"
                }, {
                    "value": "张家口市",
                    "label": "张家口市"
                }, {
                    "value": "承德市",
                    "label": "承德市"
                }, {
                    "value": "沧州市",
                    "label": "沧州市"
                }, {
                    "value": "廊坊市",
                    "label": "廊坊市"
                }, {
                    "value": "衡水市",
                    "label": "衡水市"
                }]
            }, {
                "value": "河南",
                "label": "河南",
                "children": [{
                    "value": "郑州市",
                    "label": "郑州市"
                }, {
                    "value": "开封市",
                    "label": "开封市"
                }, {
                    "value": "洛阳市",
                    "label": "洛阳市"
                }, {
                    "value": "平顶山市",
                    "label": "平顶山市"
                }, {
                    "value": "安阳市",
                    "label": "安阳市"
                }, {
                    "value": "鹤壁市",
                    "label": "鹤壁市"
                }, {
                    "value": "新乡市",
                    "label": "新乡市"
                }, {
                    "value": "焦作市",
                    "label": "焦作市"
                }, {
                    "value": "濮阳市",
                    "label": "濮阳市"
                }, {
                    "value": "许昌市",
                    "label": "许昌市"
                }, {
                    "value": "漯河市",
                    "label": "漯河市"
                }, {
                    "value": "三门峡市",
                    "label": "三门峡市"
                }, {
                    "value": "南阳市",
                    "label": "南阳市"
                }, {
                    "value": "商丘市",
                    "label": "商丘市"
                }, {
                    "value": "信阳市",
                    "label": "信阳市"
                }, {
                    "value": "周口市",
                    "label": "周口市"
                }, {
                    "value": "驻马店市",
                    "label": "驻马店市"
                }]
            }, {
                "value": "福建",
                "label": "福建",
                "children": [{
                    "value": "福州市",
                    "label": "福州市"
                }, {
                    "value": "厦门市",
                    "label": "厦门市"
                }, {
                    "value": "莆田市",
                    "label": "莆田市"
                }, {
                    "value": "三明市",
                    "label": "三明市"
                }, {
                    "value": "泉州市",
                    "label": "泉州市"
                }, {
                    "value": "漳州市",
                    "label": "漳州市"
                }, {
                    "value": "南平市",
                    "label": "南平市"
                }, {
                    "value": "龙岩市",
                    "label": "龙岩市"
                }, {
                    "value": "宁德市",
                    "label": "宁德市"
                }]
            }, {
                "value": "湖北",
                "label": "湖北",
                "children": [{
                    "value": "武汉市",
                    "label": "武汉市"
                }, {
                    "value": "黄石市",
                    "label": "黄石市"
                }, {
                    "value": "十堰市",
                    "label": "十堰市"
                }, {
                    "value": "宜昌市",
                    "label": "宜昌市"
                }, {
                    "value": "襄阳市",
                    "label": "襄阳市"
                }, {
                    "value": "鄂州市",
                    "label": "鄂州市"
                }, {
                    "value": "荆门市",
                    "label": "荆门市"
                }, {
                    "value": "孝感市",
                    "label": "孝感市"
                }, {
                    "value": "荆州市",
                    "label": "荆州市"
                }, {
                    "value": "黄冈市",
                    "label": "黄冈市"
                }, {
                    "value": "咸宁市",
                    "label": "咸宁市"
                }, {
                    "value": "随州市",
                    "label": "随州市"
                }, {
                    "value": "恩施土家族苗族自治州",
                    "label": "恩施土家族苗族自治州"
                }]
            }, {
                "value": "安徽",
                "label": "安徽",
                "children": [{
                    "value": "合肥市",
                    "label": "合肥市"
                }, {
                    "value": "芜湖市",
                    "label": "芜湖市"
                }, {
                    "value": "蚌埠市",
                    "label": "蚌埠市"
                }, {
                    "value": "淮南市",
                    "label": "淮南市"
                }, {
                    "value": "马鞍山市",
                    "label": "马鞍山市"
                }, {
                    "value": "淮北市",
                    "label": "淮北市"
                }, {
                    "value": "铜陵市",
                    "label": "铜陵市"
                }, {
                    "value": "安庆市",
                    "label": "安庆市"
                }, {
                    "value": "黄山市",
                    "label": "黄山市"
                }, {
                    "value": "滁州市",
                    "label": "滁州市"
                }, {
                    "value": "阜阳市",
                    "label": "阜阳市"
                }, {
                    "value": "宿州市",
                    "label": "宿州市"
                }, {
                    "value": "六安市",
                    "label": "六安市"
                }, {
                    "value": "亳州市",
                    "label": "亳州市"
                }, {
                    "value": "池州市",
                    "label": "池州市"
                }, {
                    "value": "宣城市",
                    "label": "宣城市"
                }]
            }, {
                "value": "重庆",
                "label": "重庆",
                "children": [{
                    "value": "重庆市",
                    "label": "重庆市"
                }]
            }, {
                "value": "陕西",
                "label": "陕西",
                "children": [{
                    "value": "西安市",
                    "label": "西安市"
                }, {
                    "value": "铜川市",
                    "label": "铜川市"
                }, {
                    "value": "宝鸡市",
                    "label": "宝鸡市"
                }, {
                    "value": "咸阳市",
                    "label": "咸阳市"
                }, {
                    "value": "渭南市",
                    "label": "渭南市"
                }, {
                    "value": "延安市",
                    "label": "延安市"
                }, {
                    "value": "汉中市",
                    "label": "汉中市"
                }, {
                    "value": "榆林市",
                    "label": "榆林市"
                }, {
                    "value": "安康市",
                    "label": "安康市"
                }, {
                    "value": "商洛市",
                    "label": "商洛市"
                }]
            }, {
                "value": "湖南",
                "label": "湖南",
                "children": [{
                    "value": "长沙市",
                    "label": "长沙市"
                }, {
                    "value": "株洲市",
                    "label": "株洲市"
                }, {
                    "value": "湘潭市",
                    "label": "湘潭市"
                }, {
                    "value": "衡阳市",
                    "label": "衡阳市"
                }, {
                    "value": "邵阳市",
                    "label": "邵阳市"
                }, {
                    "value": "岳阳市",
                    "label": "岳阳市"
                }, {
                    "value": "常德市",
                    "label": "常德市"
                }, {
                    "value": "张家界市",
                    "label": "张家界市"
                }, {
                    "value": "益阳市",
                    "label": "益阳市"
                }, {
                    "value": "郴州市",
                    "label": "郴州市"
                }, {
                    "value": "永州市",
                    "label": "永州市"
                }, {
                    "value": "怀化市",
                    "label": "怀化市"
                }, {
                    "value": "娄底市",
                    "label": "娄底市"
                }, {
                    "value": "湘西土家族苗族自治州",
                    "label": "湘西土家族苗族自治州"
                }]
            }, {
                "value": "辽宁",
                "label": "辽宁",
                "children": [{
                    "value": "沈阳市",
                    "label": "沈阳市"
                }, {
                    "value": "大连市",
                    "label": "大连市"
                }, {
                    "value": "鞍山市",
                    "label": "鞍山市"
                }, {
                    "value": "抚顺市",
                    "label": "抚顺市"
                }, {
                    "value": "本溪市",
                    "label": "本溪市"
                }, {
                    "value": "丹东市",
                    "label": "丹东市"
                }, {
                    "value": "锦州市",
                    "label": "锦州市"
                }, {
                    "value": "营口市",
                    "label": "营口市"
                }, {
                    "value": "阜新市",
                    "label": "阜新市"
                }, {
                    "value": "辽阳市",
                    "label": "辽阳市"
                }, {
                    "value": "盘锦市",
                    "label": "盘锦市"
                }, {
                    "value": "铁岭市",
                    "label": "铁岭市"
                }, {
                    "value": "朝阳市",
                    "label": "朝阳市"
                }, {
                    "value": "葫芦岛市",
                    "label": "葫芦岛市"
                }]
            }, {
                "value": "天津",
                "label": "天津",
                "children": [{
                    "value": "天津市",
                    "label": "天津市"
                }]
            }, {
                "value": "江西",
                "label": "江西",
                "children": [{
                    "value": "南昌市",
                    "label": "南昌市"
                }, {
                    "value": "景德镇市",
                    "label": "景德镇市"
                }, {
                    "value": "萍乡市",
                    "label": "萍乡市"
                }, {
                    "value": "九江市",
                    "label": "九江市"
                }, {
                    "value": "新余市",
                    "label": "新余市"
                }, {
                    "value": "鹰潭市",
                    "label": "鹰潭市"
                }, {
                    "value": "赣州市",
                    "label": "赣州市"
                }, {
                    "value": "吉安市",
                    "label": "吉安市"
                }, {
                    "value": "宜春市",
                    "label": "宜春市"
                }, {
                    "value": "抚州市",
                    "label": "抚州市"
                }, {
                    "value": "上饶市",
                    "label": "上饶市"
                }]
            }, {
                "value": "云南",
                "label": "云南",
                "children": [{
                    "value": "昆明市",
                    "label": "昆明市"
                }, {
                    "value": "曲靖市",
                    "label": "曲靖市"
                }, {
                    "value": "玉溪市",
                    "label": "玉溪市"
                }, {
                    "value": "保山市",
                    "label": "保山市"
                }, {
                    "value": "昭通市",
                    "label": "昭通市"
                }, {
                    "value": "丽江市",
                    "label": "丽江市"
                }, {
                    "value": "普洱市",
                    "label": "普洱市"
                }, {
                    "value": "临沧市",
                    "label": "临沧市"
                }, {
                    "value": "楚雄彝族自治州",
                    "label": "楚雄彝族自治州"
                }, {
                    "value": "红河哈尼族彝族自治州",
                    "label": "红河哈尼族彝族自治州"
                }, {
                    "value": "文山壮族苗族自治州",
                    "label": "文山壮族苗族自治州"
                }, {
                    "value": "西双版纳傣族自治州",
                    "label": "西双版纳傣族自治州"
                }, {
                    "value": "大理白族自治州",
                    "label": "大理白族自治州"
                }, {
                    "value": "德宏傣族景颇族自治州",
                    "label": "德宏傣族景颇族自治州"
                }, {
                    "value": "怒江傈僳族自治州",
                    "label": "怒江傈僳族自治州"
                }, {
                    "value": "迪庆藏族自治州",
                    "label": "迪庆藏族自治州"
                }]
            }, {
                "value": "广西",
                "label": "广西",
                "children": [{
                    "value": "南宁市",
                    "label": "南宁市"
                }, {
                    "value": "柳州市",
                    "label": "柳州市"
                }, {
                    "value": "桂林市",
                    "label": "桂林市"
                }, {
                    "value": "梧州市",
                    "label": "梧州市"
                }, {
                    "value": "北海市",
                    "label": "北海市"
                }, {
                    "value": "防城港市",
                    "label": "防城港市"
                }, {
                    "value": "钦州市",
                    "label": "钦州市"
                }, {
                    "value": "贵港市",
                    "label": "贵港市"
                }, {
                    "value": "玉林市",
                    "label": "玉林市"
                }, {
                    "value": "百色市",
                    "label": "百色市"
                }, {
                    "value": "贺州市",
                    "label": "贺州市"
                }, {
                    "value": "河池市",
                    "label": "河池市"
                }, {
                    "value": "来宾市",
                    "label": "来宾市"
                }, {
                    "value": "崇左市",
                    "label": "崇左市"
                }]
            }, {
                "value": "山西",
                "label": "山西",
                "children": [{
                    "value": "太原市",
                    "label": "太原市"
                }, {
                    "value": "大同市",
                    "label": "大同市"
                }, {
                    "value": "阳泉市",
                    "label": "阳泉市"
                }, {
                    "value": "长治市",
                    "label": "长治市"
                }, {
                    "value": "晋城市",
                    "label": "晋城市"
                }, {
                    "value": "朔州市",
                    "label": "朔州市"
                }, {
                    "value": "晋中市",
                    "label": "晋中市"
                }, {
                    "value": "运城市",
                    "label": "运城市"
                }, {
                    "value": "忻州市",
                    "label": "忻州市"
                }, {
                    "value": "临汾市",
                    "label": "临汾市"
                }, {
                    "value": "吕梁市",
                    "label": "吕梁市"
                }]
            }, {
                "value": "贵州",
                "label": "贵州",
                "children": [{
                    "value": "贵阳市",
                    "label": "贵阳市"
                }, {
                    "value": "六盘水市",
                    "label": "六盘水市"
                }, {
                    "value": "遵义市",
                    "label": "遵义市"
                }, {
                    "value": "安顺市",
                    "label": "安顺市"
                }, {
                    "value": "毕节市",
                    "label": "毕节市"
                }, {
                    "value": "铜仁市",
                    "label": "铜仁市"
                }, {
                    "value": "黔西南布依族苗族自治州",
                    "label": "黔西南布依族苗族自治州"
                }, {
                    "value": "黔东南苗族侗族自治州",
                    "label": "黔东南苗族侗族自治州"
                }, {
                    "value": "黔南布依族苗族自治州",
                    "label": "黔南布依族苗族自治州"
                }]
            }, {
                "value": "吉林",
                "label": "吉林",
                "children": [{
                    "value": "长春市",
                    "label": "长春市"
                }, {
                    "value": "吉林市",
                    "label": "吉林市"
                }, {
                    "value": "四平市",
                    "label": "四平市"
                }, {
                    "value": "辽源市",
                    "label": "辽源市"
                }, {
                    "value": "通化市",
                    "label": "通化市"
                }, {
                    "value": "白山市",
                    "label": "白山市"
                }, {
                    "value": "松原市",
                    "label": "松原市"
                }, {
                    "value": "白城市",
                    "label": "白城市"
                }, {
                    "value": "延边朝鲜族自治州",
                    "label": "延边朝鲜族自治州"
                }]
            }, {
                "value": "黑龙江",
                "label": "黑龙江",
                "children": [{
                    "value": "哈尔滨市",
                    "label": "哈尔滨市"
                }, {
                    "value": "齐齐哈尔市",
                    "label": "齐齐哈尔市"
                }, {
                    "value": "鸡西市",
                    "label": "鸡西市"
                }, {
                    "value": "鹤岗市",
                    "label": "鹤岗市"
                }, {
                    "value": "双鸭山市",
                    "label": "双鸭山市"
                }, {
                    "value": "大庆市",
                    "label": "大庆市"
                }, {
                    "value": "伊春市",
                    "label": "伊春市"
                }, {
                    "value": "佳木斯市",
                    "label": "佳木斯市"
                }, {
                    "value": "七台河市",
                    "label": "七台河市"
                }, {
                    "value": "牡丹江市",
                    "label": "牡丹江市"
                }, {
                    "value": "黑河市",
                    "label": "黑河市"
                }, {
                    "value": "绥化市",
                    "label": "绥化市"
                }, {
                    "value": "大兴安岭地区",
                    "label": "大兴安岭地区"
                }]
            }, {
                "value": "内蒙古",
                "label": "内蒙古",
                "children": [{
                    "value": "呼和浩特市",
                    "label": "呼和浩特市"
                }, {
                    "value": "包头市",
                    "label": "包头市"
                }, {
                    "value": "乌海市",
                    "label": "乌海市"
                }, {
                    "value": "赤峰市",
                    "label": "赤峰市"
                }, {
                    "value": "通辽市",
                    "label": "通辽市"
                }, {
                    "value": "鄂尔多斯市",
                    "label": "鄂尔多斯市"
                }, {
                    "value": "呼伦贝尔市",
                    "label": "呼伦贝尔市"
                }, {
                    "value": "巴彦淖尔市",
                    "label": "巴彦淖尔市"
                }, {
                    "value": "乌兰察布市兴安盟",
                    "label": "乌兰察布市兴安盟"
                }, {
                    "value": "锡林郭勒盟",
                    "label": "锡林郭勒盟"
                }, {
                    "value": "阿拉善盟",
                    "label": "阿拉善盟"
                }]
            }, {
                "value": "新疆",
                "label": "新疆",
                "children": [{
                    "value": "乌鲁木齐市",
                    "label": "乌鲁木齐市"
                }, {
                    "value": "克拉玛依市",
                    "label": "克拉玛依市"
                }, {
                    "value": "吐鲁番市",
                    "label": "吐鲁番市"
                }, {
                    "value": "哈密市",
                    "label": "哈密市"
                }, {
                    "value": "昌吉回族自治州",
                    "label": "昌吉回族自治州"
                }, {
                    "value": "博尔塔拉蒙古自治州",
                    "label": "博尔塔拉蒙古自治州"
                }, {
                    "value": "巴音郭楞蒙古自治州",
                    "label": "巴音郭楞蒙古自治州"
                }, {
                    "value": "阿克苏地区",
                    "label": "阿克苏地区"
                }, {
                    "value": "克孜勒苏柯尔克孜自治州",
                    "label": "克孜勒苏柯尔克孜自治州"
                }, {
                    "value": "喀什地区",
                    "label": "喀什地区"
                }, {
                    "value": "和田地区",
                    "label": "和田地区"
                }, {
                    "value": "伊犁哈萨克自治州",
                    "label": "伊犁哈萨克自治州"
                }, {
                    "value": "塔城地区",
                    "label": "塔城地区"
                }, {
                    "value": "阿勒泰地区",
                    "label": "阿勒泰地区"
                }]
            }, {
                "value": "甘肃",
                "label": "甘肃",
                "children": [{
                    "value": "兰州市",
                    "label": "兰州市"
                }, {
                    "value": "嘉峪关市",
                    "label": "嘉峪关市"
                }, {
                    "value": "金昌市",
                    "label": "金昌市"
                }, {
                    "value": "白银市",
                    "label": "白银市"
                }, {
                    "value": "天水市",
                    "label": "天水市"
                }, {
                    "value": "武威市",
                    "label": "武威市"
                }, {
                    "value": "张掖市",
                    "label": "张掖市"
                }, {
                    "value": "平凉市",
                    "label": "平凉市"
                }, {
                    "value": "酒泉市",
                    "label": "酒泉市"
                }, {
                    "value": "庆阳市",
                    "label": "庆阳市"
                }, {
                    "value": "定西市",
                    "label": "定西市"
                }, {
                    "value": "陇南市",
                    "label": "陇南市"
                }, {
                    "value": "临夏回族自治州",
                    "label": "临夏回族自治州"
                }, {
                    "value": "甘南藏族自治州",
                    "label": "甘南藏族自治州"
                }]
            }, {
                "value": "海南",
                "label": "海南",
                "children": [{
                    "value": "海口市",
                    "label": "海口市"
                }, {
                    "value": "三亚市",
                    "label": "三亚市"
                }, {
                    "value": "三沙市",
                    "label": "三沙市"
                }, {
                    "value": "儋州市",
                    "label": "儋州市"
                }]
            }, {
                "value": "宁夏",
                "label": "宁夏",
                "children": [{
                    "value": "银川市",
                    "label": "银川市"
                }, {
                    "value": "嘴山市",
                    "label": "嘴山市"
                }, {
                    "value": "吴忠市",
                    "label": "吴忠市"
                }, {
                    "value": "固原市",
                    "label": "固原市"
                }, {
                    "value": "中卫市",
                    "label": "中卫市"
                }]
            }, {
                "value": "青海",
                "label": "青海",
                "children": [{
                    "value": "西宁市",
                    "label": "西宁市"
                }, {
                    "value": "海东市",
                    "label": "海东市"
                }, {
                    "value": "海北藏族自治州",
                    "label": "海北藏族自治州"
                }, {
                    "value": "黄南藏族自治州",
                    "label": "黄南藏族自治州"
                }, {
                    "value": "海南藏族自治州",
                    "label": "海南藏族自治州"
                }, {
                    "value": "果洛藏族自治州",
                    "label": "果洛藏族自治州"
                }, {
                    "value": "玉树藏族自治州",
                    "label": "玉树藏族自治州"
                }, {
                    "value": "海西蒙古族藏族自治州",
                    "label": "海西蒙古族藏族自治州"
                }]
            }, {
                "value": "西藏",
                "label": "西藏",
                "children": [{
                    "value": "拉萨市",
                    "label": "拉萨市"
                }, {
                    "value": "日喀则市",
                    "label": "日喀则市"
                }, {
                    "value": "昌都市",
                    "label": "昌都市"
                }, {
                    "value": "林芝市",
                    "label": "林芝市"
                }, {
                    "value": "山南市",
                    "label": "山南市"
                }, {
                    "value": "那曲地区",
                    "label": "那曲地区"
                }, {
                    "value": "阿里地区",
                    "label": "阿里地区"
                }]
            }],
            categoryOptions: [{
                "value": 1,
                "label": "农、林、牧、渔业",
                "level": 1,
                "children": [{
                    "value": 2,
                    "label": "农业",
                    "level": 2
                }, {
                    "value": 37,
                    "label": "林业",
                    "level": 2
                }, {
                    "value": 51,
                    "label": "畜牧业",
                    "level": 2
                }, {
                    "value": 68,
                    "label": "渔业",
                    "level": 2
                }, {
                    "value": 75,
                    "label": "农、林、牧、渔服务业",
                    "level": 2
                }]
            }, {
                "value": 90,
                "label": "采矿业",
                "level": 1,
                "children": [{
                    "value": 91,
                    "label": "煤炭开采和洗选业",
                    "level": 2
                }, {
                    "value": 98,
                    "label": "石油和天然气开采业",
                    "level": 2
                }, {
                    "value": 103,
                    "label": "黑色金属矿采选业",
                    "level": 2
                }, {
                    "value": 110,
                    "label": "有色金属矿采选业",
                    "level": 2
                }, {
                    "value": 129,
                    "label": "非金属矿采选业",
                    "level": 2
                }, {
                    "value": 144,
                    "label": "开采辅助活动",
                    "level": 2
                }, {
                    "value": 151,
                    "label": "其他采矿业",
                    "level": 2
                }]
            }, {
                "value": 154,
                "label": "制造业",
                "level": 1,
                "children": [{
                    "value": 155,
                    "label": "农副食品加工业",
                    "level": 2
                }, {
                    "value": 183,
                    "label": "食品制造业",
                    "level": 2
                }, {
                    "value": 212,
                    "label": "酒、饮料和精制茶制造业",
                    "level": 2
                }, {
                    "value": 229,
                    "label": "烟草制品业",
                    "level": 2
                }, {
                    "value": 236,
                    "label": "纺织业",
                    "level": 2
                }, {
                    "value": 271,
                    "label": "纺织服装、服饰业",
                    "level": 2
                }, {
                    "value": 278,
                    "label": "皮革、毛皮、羽毛及其制品和制鞋业",
                    "level": 2
                }, {
                    "value": 299,
                    "label": "木材加工和木、竹、藤、棕、草制品业",
                    "level": 2
                }, {
                    "value": 321,
                    "label": "家具制造业",
                    "level": 2
                }, {
                    "value": 332,
                    "label": "造纸和纸制品业",
                    "level": 2
                }, {
                    "value": 343,
                    "label": "印刷和记录媒介复制业",
                    "level": 2
                }, {
                    "value": 352,
                    "label": "文教、工美、体育和娱乐用品制造业",
                    "level": 2
                }, {
                    "value": 386,
                    "label": "石油加工、炼焦和核燃料加工业",
                    "level": 2
                }, {
                    "value": 394,
                    "label": "化学原料和化学制品制造业",
                    "level": 2
                }, {
                    "value": 439,
                    "label": "医药制造业",
                    "level": 2
                }, {
                    "value": 454,
                    "label": "化学纤维制造业",
                    "level": 2
                }, {
                    "value": 466,
                    "label": "橡胶和塑料制品业",
                    "level": 2
                }, {
                    "value": 484,
                    "label": "非金属矿物制品业",
                    "level": 2
                }, {
                    "value": 528,
                    "label": "黑色金属冶炼和压延加工业",
                    "level": 2
                }, {
                    "value": 539,
                    "label": "有色金属冶炼和压延加工业",
                    "level": 2
                }, {
                    "value": 567,
                    "label": "金属制品业",
                    "level": 2
                }, {
                    "value": 604,
                    "label": "通用设备制造业",
                    "level": 2
                }, {
                    "value": 659,
                    "label": "专用设备制造业",
                    "level": 2
                }, {
                    "value": 721,
                    "label": "汽车制造业",
                    "level": 2
                }, {
                    "value": 734,
                    "label": "铁路、船舶、航空航天和其他运输设备制造业",
                    "level": 2
                }, {
                    "value": 766,
                    "label": "电气机械和器材制造业",
                    "level": 2
                }, {
                    "value": 806,
                    "label": "计算机、通信和其他电子设备制造业",
                    "level": 2
                }, {
                    "value": 835,
                    "label": "仪器仪表制造业",
                    "level": 2
                }, {
                    "value": 860,
                    "label": "其他制造业",
                    "level": 2
                }, {
                    "value": 870,
                    "label": "废弃资源综合利用业",
                    "level": 2
                }, {
                    "value": 875,
                    "label": "金属制品、机械和设备修理业",
                    "level": 2
                }]
            }, {
                "value": 893,
                "label": "电力、热力、燃气及水生产和供应业",
                "level": 1,
                "children": [{
                    "value": 894,
                    "label": "电力、热力生产和供应业",
                    "level": 2
                }, {
                    "value": 906,
                    "label": "燃气生产和供应业",
                    "level": 2
                }, {
                    "value": 909,
                    "label": "水的生产和供应业",
                    "level": 2
                }]
            }, {
                "value": 916,
                "label": "建筑业",
                "level": 1,
                "children": [{
                    "value": 917,
                    "label": "房屋建筑业",
                    "level": 2
                }, {
                    "value": 920,
                    "label": "土木工程建筑业",
                    "level": 2
                }, {
                    "value": 939,
                    "label": "建筑安装业",
                    "level": 2
                }, {
                    "value": 946,
                    "label": "建筑装饰和其他建筑业",
                    "level": 2
                }]
            }, {
                "value": 956,
                "label": "批发和零售业",
                "level": 1,
                "children": [{
                    "value": 957,
                    "label": "批发业",
                    "level": 2
                }, {
                    "value": 1024,
                    "label": "零售业",
                    "level": 2
                }]
            }, {
                "value": 1090,
                "label": "交通运输、仓储和邮政业",
                "level": 1,
                "children": [{
                    "value": 1091,
                    "label": "铁路运输业",
                    "level": 2
                }, {
                    "value": 1100,
                    "label": "道路运输业",
                    "level": 2
                }, {
                    "value": 1114,
                    "label": "水上运输业",
                    "level": 2
                }, {
                    "value": 1127,
                    "label": "航空运输业",
                    "level": 2
                }, {
                    "value": 1137,
                    "label": "管道运输业",
                    "level": 2
                }, {
                    "value": 1140,
                    "label": "装卸搬运和运输代理业",
                    "level": 2
                }, {
                    "value": 1147,
                    "label": "仓储业",
                    "level": 2
                }, {
                    "value": 1154,
                    "label": "邮政业",
                    "level": 2
                }]
            }, {
                "value": 1159,
                "label": "住宿和餐饮业",
                "level": 1,
                "children": [{
                    "value": 1160,
                    "label": "住宿业",
                    "level": 2
                }, {
                    "value": 1167,
                    "label": "餐饮业",
                    "level": 2
                }]
            }, {
                "value": 1181,
                "label": "信息传输、软件和信息技术服务业",
                "level": 1,
                "children": [{
                    "value": 1182,
                    "label": "电信、广播电视和卫星传输服务",
                    "level": 2
                }, {
                    "value": 1192,
                    "label": "互联网和相关服务",
                    "level": 2
                }, {
                    "value": 1199,
                    "label": "软件和信息技术服务业",
                    "level": 2
                }]
            }, {
                "value": 1214,
                "label": "金融业",
                "level": 1,
                "children": [{
                    "value": 1215,
                    "label": "货币金融服务",
                    "level": 2
                }, {
                    "value": 1227,
                    "label": "资本市场服务",
                    "level": 2
                }, {
                    "value": 1241,
                    "label": "保险业",
                    "level": 2
                }, {
                    "value": 1258,
                    "label": "其他金融业",
                    "level": 2
                }]
            }, {
                "value": 1269,
                "label": "房地产业",
                "level": 1,
                "children": [{
                    "value": 1270,
                    "label": "房地产业",
                    "level": 2
                }]
            }, {
                "value": 1281,
                "label": "租赁和商务服务业",
                "level": 1,
                "children": [{
                    "value": 1282,
                    "label": "租赁业",
                    "level": 2
                }, {
                    "value": 1294,
                    "label": "商务服务业",
                    "level": 2
                }]
            }, {
                "value": 1334,
                "label": "科学研究和技术服务业",
                "level": 1,
                "children": [{
                    "value": 1335,
                    "label": "研究和试验发展",
                    "level": 2
                }, {
                    "value": 1346,
                    "label": "专业技术服务业",
                    "level": 2
                }, {
                    "value": 1375,
                    "label": "科技推广和应用服务业",
                    "level": 2
                }]
            }, {
                "value": 1386,
                "label": "水利、环境和公共设施管理业",
                "level": 1,
                "children": [{
                    "value": 1387,
                    "label": "水利管理业",
                    "level": 2
                }, {
                    "value": 1398,
                    "label": "生态保护和环境治理业",
                    "level": 2
                }, {
                    "value": 1411,
                    "label": "公共设施管理业",
                    "level": 2
                }]
            }, {
                "value": 1423,
                "label": "居民服务、修理和其他服务业",
                "level": 1,
                "children": [{
                    "value": 1424,
                    "label": "居民服务业",
                    "level": 2
                }, {
                    "value": 1443,
                    "label": "机动车、电子产品和日用产品修理业",
                    "level": 2
                }, {
                    "value": 1459,
                    "label": "其他服务业",
                    "level": 2
                }]
            }, {
                "value": 1465,
                "label": "教育",
                "level": 1,
                "children": [{
                    "value": 1466,
                    "label": "教育",
                    "level": 2
                }]
            }, {
                "value": 1490,
                "label": "卫生和社会工作",
                "level": 1,
                "children": [{
                    "value": 1491,
                    "label": "卫生",
                    "level": 2
                }, {
                    "value": 1515,
                    "label": "社会工作",
                    "level": 2
                }]
            }, {
                "value": 1526,
                "label": "文化、体育和娱乐业",
                "level": 1,
                "children": [{
                    "value": 1527,
                    "label": "新闻和出版业",
                    "level": 2
                }, {
                    "value": 1537,
                    "label": "广播、电视、电影和影视录音制作业",
                    "level": 2
                }, {
                    "value": 1550,
                    "label": "文化艺术业",
                    "level": 2
                }, {
                    "value": 1568,
                    "label": "体育",
                    "level": 2
                }, {
                    "value": 1577,
                    "label": "娱乐业",
                    "level": 2
                }]
            }, {
                "value": 1593,
                "label": "公共管理、社会保障和社会组织",
                "level": 1,
                "children": [{
                    "value": 1594,
                    "label": "中国共产党机关",
                    "level": 2
                }, {
                    "value": 1597,
                    "label": "国家机构",
                    "level": 2
                }, {
                    "value": 1612,
                    "label": "人民政协、民主党派",
                    "level": 2
                }, {
                    "value": 1617,
                    "label": "社会保障",
                    "level": 2
                }, {
                    "value": 1620,
                    "label": "群众团体、社会团体和其他成员组织",
                    "level": 2
                }, {
                    "value": 1634,
                    "label": "基层群众自治组织",
                    "level": 2
                }]
            }, {
                "value": 1639,
                "label": "国际组织",
                "level": 1,
                "children": [{
                    "value": 1640,
                    "label": "国际组织",
                    "level": 2
                }]
            }],
            // 查询范围
            s_scope: [
                {
                    label: "公司名",
                    value: 1,
                },
                {
                    label: "网站",
                    value: 2,
                },
                {
                    label: "广告推广",
                    value: 3,
                },
                {
                    label: "经营范围",
                    value: 4,
                }
            ],
            //推广平台
            s_platform: [
                {
                    label: "百度PC",
                    value: 1,
                },
                {
                    label: "百度移动",
                    value: 2,
                },
                {
                    label: "360PC",
                    value: 3,
                },
                {
                    label: "360移动",
                    value: 4,
                },
                {
                    label: "搜狗PC",
                    value: 5,
                },
                {
                    label: "搜狗移动",
                    value: 6,
                },
                {
                    label: "神马搜索",
                    value: 7,
                }
            ],
            // 注册资本
            s_money: [
                {
                    label: "100万以内",
                    value: [null, 100],
                },
                {
                    label: "100~500万",
                    value: [100, 500],
                },
                {
                    label: "500~1000万",
                    value: [500, 1000],
                },
                {
                    label: "1000~5000万",
                    value: [1000, 5000],
                },
                {
                    label: "5000万以上",
                    value: [5000, null],
                },
            ],
            // 成立年限
            s_age: [
                {
                    label: "1年内",
                    value: [null, 1],
                },
                {
                    label: "1~3年",
                    value: [1, 3],
                },
                {
                    label: "3~5年",
                    value: [3, 5],
                },
                {
                    label: "5~10年",
                    value: [5, 10],
                },
                {
                    label: "10年以上",
                    value: [10, null],
                },
            ]
        }
    },
    computed: {
        areaObj() {
            let obj = {}
            this.areaOptions.map(v => {
                obj[v.label] = v.children.length
            })
            return obj
        },
        categoryObj() {
            let obj = {}
            this.categoryOptions.map(v => {
                obj[v.value] = v.children.length
            })
            return obj
        },
        categoryNameObj() {
            let obj = {}
            this.categoryOptions.map(v => {
                obj[v.value] = v.label
                v.children.map(el => {
                    obj[el.value] = el.label
                })
            })
            return obj
        }
    },
    methods: {
        // 清除条件
        clearAll() {
            this.$refs.platformSelector.clear()
            this.area_name_str = ''
            this.area_name = '全部地区'
            this.category_name_str = ''
            this.category_name = '全部行业'
            this.moneyStatus = true
            this.ageStatus = true
            this.search.s_area = []
            this.search.s_scope = []
            this.search.s_category = []
            this.search.s_platform = []
            this.search.s_money = []
            this.search.s_age = []
            this.search.page = 1;
            this.search.sort = 1;
            this.search.limit = 10;
            this.init()
        },
        // // 关键词
        // keywordsClick() {
        //     if (!this.search.keywords || this.search.keywords == 'null' || this.search.keywords == 'undefined') {
        //         this.$router.push({
        //             path: "/",
        //         });
        //         return false
        //     }
        //
        //     window.location.href = '/list?keywords=' + this.search.keywords
        //
        //     // this.$router.push({
        //     //     path: '/list',
        //     //     query: {
        //     //         keywords: this.search.keywords,
        //     //     },
        //     // });
        // },
        // 查询范围
        scopeSelect(v) {
            if (this.search.s_scope.includes(v)) {
                let index = this.search.s_scope.indexOf(v);
                this.search.s_scope.splice(index, 1);
            } else {
                this.search.s_scope.push(v);
            }
            this.init()
        },
        //推广平台
        platSelectSelect(list) {
            this.search.s_platform = list;
            this.init()
        },
        // 所选地区
        areaSelect(v) {
            let getAreaObj = {}
            v.map(el => {
                if (getAreaObj[el[0]]) {
                    getAreaObj[el[0]].push(el[1])
                } else {
                    getAreaObj[el[0]] = [el[1]]
                }
            })

            let num = 0
            this.area_name_str = []
            for (const key in getAreaObj) {
                if (Object.hasOwnProperty.call(getAreaObj, key)) {
                    const el = getAreaObj[key];
                    if (this.areaObj[key] == el.length) {
                        num++
                        this.area_name_str.push(key)
                    } else {
                        num += el.length
                        this.area_name_str = this.area_name_str.concat(el)
                    }
                }
            }

            this.area_name_str = this.area_name_str.join('/')

            this.init()
            
            if(num==0) {
                this.area_name = '全部地区'
            } else {
                this.area_name = '已选' + num + '项'
            }
            this.search.s_area = v;
        },
        // 所选行业
        categorySelect(v) {
            let getCategoryObj = {}
            v.map(el => {
                if (getCategoryObj[el[0]]) {
                    getCategoryObj[el[0]].push(el[1])
                } else {
                    getCategoryObj[el[0]] = [el[1]]
                }
            })

            let num = 0
            this.category_name_str = []
            for (const key in getCategoryObj) {
                if (Object.hasOwnProperty.call(getCategoryObj, key)) {
                    const el = getCategoryObj[key];
                    if (this.categoryObj[key] == el.length) {
                        num++
                        this.category_name_str.push(this.categoryNameObj[key])
                    } else {
                        num += el.length
                        el.map(ele => {
                            this.category_name_str = this.category_name_str.concat(this.categoryNameObj[ele])
                        })
                    }
                }
            }

            this.category_name_str = this.category_name_str.join('/')

            this.init()
            if(num==0) {
                this.category_name = '全部行业'
            } else {
                this.category_name = '已选' + num + '项'
            }
            this.search.s_category = v;
        },
        // // 企业类型
        // typeSelect(v) {
        //     this.search.s_type = [v];
        //     this.init()
        // },
        // // 企业状态
        // statusSelect(v) {
        //     this.search.s_status = [v];
        //     this.init()
        // },
        // 注册资本
        moneySelect(v) {
            if(!this.moneyStatus) {
                this.search.s_money = []
            }
            this.moneyStatus = true
            this.moneyMaxNotice = false
            this.moneyMinNotice = false
            this.moneyMin = 0
            this.moneyMax = 100
            if (this.search.s_money.includes(v)) {
                let index = this.search.s_money.indexOf(v);
                this.search.s_money.splice(index, 1);
            } else {
                this.search.s_money.push(v);
            }
            this.init()
        },
        // 自定义注册资本
        myMoneySure() {
            if (this.$C.isNumber(this.moneyMin) && this.$C.isNumber(this.moneyMax) && this.moneyMin >= 0 && this.moneyMax >= 0) {
                if (this.moneyMin >= this.moneyMax) {
                    this.moneyStatus = false
                    this.moneyVisible = false
                    this.moneyMaxNotice = false
                    this.moneyMinNotice = false
                    this.search.s_money = [[this.moneyMax, this.moneyMin]]
                } else {
                    this.search.s_money = [[this.moneyMin, this.moneyMax]]
                    this.moneyStatus = false
                    this.moneyVisible = false
                    this.moneyMaxNotice = false
                    this.moneyMinNotice = false
                }
            } else {
                this.moneyStatus = true
                this.moneyMinNotice = false
                this.moneyMaxNotice = false
                return false
            }
            this.init()
        },
        // 成立年限
        ageSelect(v) {
            if(!this.ageStatus) {
                this.search.s_age = []
            }
            this.ageStatus = true
            this.ageMinNotice = false
            this.ageMaxNotice = false
            this.ageMin = 0
            this.ageMax = 10
            if (this.search.s_age.includes(v)) {
                let index = this.search.s_age.indexOf(v);
                this.search.s_age.splice(index, 1);
            } else {
                this.search.s_age.push(v);
            }
            this.init()
        },
        // 自定义成立年限
        myAgeSure() {
            if (this.$C.isNumber(this.ageMin) && this.$C.isNumber(this.ageMax) && this.ageMin >= 0 && this.ageMax >= 0) {
                if (this.ageMin >= this.ageMax) {
                    this.ageStatus = false
                    this.ageMaxNotice = false
                    this.ageVisible = false
                    this.ageMinNotice = false
                    this.search.s_age = [[this.ageMax, this.ageMin]]        //传入的数据暂未处理，为string类型
                } else {
                    this.search.s_age = [[this.ageMin, this.ageMax]]
                    this.ageStatus = false
                    this.ageVisible = false
                    this.ageMaxNotice = false
                    this.ageMinNotice = false
                }
            } else {
                this.ageStatus = true
                this.ageMinNotice = false
                this.ageMaxNotice = false
                return false
            }
            this.init()
        }
    },
}

export default searchOption;
